import "./App.css";
import Header from "./components/layout/Header/Header";
import Home from "./components/layout/Home/Home";
import About from "./components/layout/About/About";
import Services from "./components/layout/Services/Services";
import Gallery from "./components/layout/Gallery/Gallery";
import Quote from "./components/layout/Quote/Quote";

function App() {
  return (
    <>
      <Header />
      <Home />
      <About />
      <Services />
      <Gallery />
      <Quote />
    </>
  );
}

export default App;
