import "./Services.css";
import ServiceBox from "./ServiceBox";

function Services() {
  return (
    <section id="services-section">
      <div className="services-full-width-banner">
        <h1>Our Services</h1>
        <p>Select a service to learn more</p>

        <div className="services-wrapper">
          <ServiceBox
            imageFileName="kitchen25"
            title="Kitchen Design & Installation"
            isSelected="true"
          />
          <ServiceBox
            imageFileName="bathroom"
            title="Bathroom Design and Installation"
          />
          <ServiceBox
            imageFileName="wardrobe"
            title="Joinery & Fitted Furniture"
          />
          <ServiceBox
            imageFileName="stair_storage"
            title="Bespoke Storage Solutions"
          />
          <ServiceBox imageFileName="door" title="Door Hanging" />
        </div>
      </div>
    </section>
  );
}

export default Services;
