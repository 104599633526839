import "./Home.css";
import slideImages from "../../../assets/slideshow_pics";
import Slideshow from "../../features/Slideshow/Slideshow";

function Home() {
  return (
    <section className="home-section">
      <div className="slideshow-wrapper">
        <Slideshow images={slideImages} slideInterval={3000} />
        <div className="float-over-image">
          <h1 className="title-text">
            Aldwick Interiors - Website Under Construction
          </h1>
          <p>
            Carpentry & Joinery in West Sussex ~ Phone: 07552791149 for
            enquiries
          </p>
        </div>
      </div>
    </section>
  );
}

export default Home;
