import React, { useEffect, useState } from "react";
import Slide from "./Slide";
import "./Slideshow.css";

function Slideshow({ images, slideInterval }) {
  const [imagesArray, setImagesArray] = useState(images);

  useEffect(() => {
    setTimeout(() => {
      const nextImage = imagesArray.pop();
      setImagesArray((imagesArray) => (imagesArray = [nextImage, ...imagesArray]));
    }, slideInterval);
  }, [imagesArray, slideInterval]);
  return (
    <div className="slideshow">
      {imagesArray.map((image, idx) => (
        <Slide imagePath={image} key={idx} isTop={image === imagesArray[0]} />
      ))}
    </div>
  );
}

export default Slideshow;
