import React from "react";

function Slide({ imagePath, isTop }) {
  return (
    <div
      className={`slide ${isTop && "top-slide"}`}
      style={{
        backgroundImage: `url(${imagePath})`,
      }}
    ></div>
  );
}

export default Slide;
