import "./Header.css";
import { Link } from "react-scroll";

function Header() {
  return (
    <div className="header">
      <div className="logo-wrap">
        <img
          src="./images/Logo (Version 3).png"
          alt="Logo"
          style={{ height: "100%" }}
        />
      </div>
      <div className="nav-wrap">
        <nav>
          <ul>
            <li>
              <Link
                to="home-section"
                spy={true}
                smooth={true}
                duration={200}
                activeClass="active"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="about-section"
                spy={true}
                smooth={true}
                duration={400}
                activeClass="active"
              >
                About us
              </Link>
            </li>
            <li>
              <Link
                to="services-section"
                spy={true}
                smooth={true}
                duration={600}
                activeClass="active"
              >
                Services
              </Link>
            </li>
            <li>
              <Link
                to="gallery-section"
                spy={true}
                smooth={true}
                duration={600}
                activeClass="active"
              >
                Gallery
              </Link>
            </li>
            <li className="quote-CTA-button">
              <Link
                to="contact-section"
                spy={true}
                smooth={true}
                duration={600}
                activeClass="active"
              >
                Get a Quote
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Header;
