import React from "react";

function ServiceBox({ imageFileName, title, isSelected }) {
  return (
    <div className="service-box" data={isSelected}>
      <img src={"./images/" + imageFileName + ".jpg"} alt="" width="100%" />
      <div className="service-info">
        <h3>{title}</h3>
      </div>
    </div>
  );
}

export default ServiceBox;
